<template>
  <div class="zodiac" v-if="model">
    <div class="zodiac-background">
      <img src="/images/zodiac/zodiac-bg.jpg" />
    </div>
    <div class="zodiac-detail">
      <div class="zodiac-logo">
        <span class="zodiac-image wow zoomIn" data-wow-delay="0.1s">
          <img class="effect2" @error="error" :src="'/images/zodiac/' + model.imagePath" />
        </span>
      </div>
      <div class="zodiac-content">
        <div class="zodiac-title wow fadeInDown" data-wow-delay="0.7s">BURÇLAR</div>
        <div class="zodiac-name wow fadeInDown" data-wow-delay="1.4s">{{ model.name }}</div>
        <div class="zodiac-text wow fadeInUp" data-wow-delay="1.8s">
          <p>
            {{ model.comment }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
new WOW().init();
import store from "../store/index";
export default {
  name: "daily-horoscope",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    error() {
      this.destroyed = true;
      store.dispatch("setNextPlaylist");
    },
    startTimer() {
      if (!this.model) {
        store.dispatch('setNextPlaylist');
        return;
      }
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.dailyHoroscope;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";

.zodiac-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.zodiac {
  text-align: center;
  padding-top: 22px;
}

.zodiac-detail {
  text-align: center;
  display: inline-block;
  position: relative;
  max-width: 1270px;
  width: 100%;
  height: auto;
  font-family: Montserrat-SemiBold;
  color: white;

  .zodiac-logo {
    background: url("/images/zodiac/zodiac-logo-bg.png") center top no-repeat;
    background-size: 100%;
    width: 357px;
    height: 358px;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;

    .zodiac-image {
      position: absolute;
      left: calc(50% - 44px);
      top: calc(50% - 44px);
      font-size: 42px;
      line-height: 46px;
      color: white;
      width: 88px;
      height: 88px;
      object-fit: cover;
      object-position: center;
    }
  }

  .zodiac-content {
    .zodiac-title {
      font-family: Montserrat-SemiBold;
      font-size: 50px;
      line-height: 58px;
      margin-bottom: 20px;
      display: block;
      text-transform: uppercase;
    }

    .zodiac-name {
      font-family: Montserrat-SemiBold;
      font-size: 36px;
      line-height: 62px;
      background-color: rgba(7, 49, 48, 0.67);
      border-radius: 16px;
      display: inline-block;
      padding: 0 18px;
      margin-bottom: 56px;
      text-transform: uppercase;
    }

    .zodiac-text {
      background-color: rgba(12, 72, 71, 0.67);
      border-radius: 48px;
      display: block;
      max-width: 100%;
      padding: 36px;
      font-family: Montserrat-SemiBold;
      font-size: 34px;
      line-height: 46px;

      p {
        max-height: 276px;
        -webkit-line-clamp: 6;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>





<!-- <template>
  <div class="zodiac" v-if="model">
    <div class="zodiac-background">
      <img src="/images/zodiac/zodiac-bg.jpg" />
    </div>
    <div class="zodiac-detail">
      <div class="zodiac-logo">
        <span class="zodiac-image wow zoomIn" data-wow-delay="0.1s">
          <img class="effect2" @error="error" :src="'/images/zodiac/' + model.imagePath" />
        </span>
      </div>
      <div class="zodiac-content">
        <div class="zodiac-title wow fadeInDown" data-wow-delay="0.7s">BURÇLAR</div>
        <div class="zodiac-name wow fadeInDown" data-wow-delay="1.4s">{{ model.name }}</div>
        <div class="zodiac-text wow fadeInUp" data-wow-delay="1.8s">
          <p>
            {{ model.comment }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
new WOW().init();
import store from "../store/index";
export default {
  name: "daily-horoscope",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    error() {
      this.destroyed = true;
      store.dispatch("setNextPlaylist");
    },
    startTimer() {
      if (!this.model) {
        store.dispatch('setNextPlaylist');
        return;
      }
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.dailyHoroscope;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";

.zodiac-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.zodiac {
  text-align: center;
  padding-top: 22px;
}

.zodiac-detail {
  text-align: center;
  display: inline-block;
  position: relative;
  max-width: 1270px;
  width: 100%;
  height: auto;
  font-family: Montserrat-SemiBold;
  color: white;

  .zodiac-logo {
    background: url("/images/zodiac/zodiac-logo-bg.png") center top no-repeat;
    background-size: 100%;
    width: 357px;
    height: 358px;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;

    .zodiac-image {
      position: absolute;
      left: calc(50% - 44px);
      top: calc(50% - 44px);
      font-size: 42px;
      line-height: 46px;
      color: white;
      width: 88px;
      height: 88px;
      object-fit: cover;
      object-position: center;
    }
  }

  .zodiac-content {
    .zodiac-title {
      font-family: Montserrat-SemiBold;
      font-size: 50px;
      line-height: 58px;
      margin-bottom: 20px;
      display: block;
      text-transform: uppercase;
    }

    .zodiac-name {
      font-family: Montserrat-SemiBold;
      font-size: 36px;
      line-height: 62px;
      background-color: rgba(7, 49, 48, 0.67);
      border-radius: 16px;
      display: inline-block;
      padding: 0 18px;
      margin-bottom: 56px;
      text-transform: uppercase;
    }

    .zodiac-text {
      background-color: rgba(12, 72, 71, 0.67);
      border-radius: 48px;
      display: block;
      max-width: 100%;
      padding: 36px;
      font-family: Montserrat-SemiBold;
      font-size: 34px;
      line-height: 46px;

      p {
        max-height: 276px;
        -webkit-line-clamp: 6;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}</style> -->
